<script setup>
import { onMounted, reactive } from "vue";
import { setPageTitle } from "../../../utils/pageTitle";
import { montDayYearShortTime } from "../../../utils/dateUtils";
import useUsers from "../../../hooks/useUsers";
import gravatarUrl from "gravatar-url";
import { useRoute, definePage } from "vue-router/auto";
import {
  ticketStatusColor,
  ticketPriorityColor,
  ticketCategoryColor,
} from "../../../utils/supportTicketUtils";
import { useRouter } from "vue-router";
import { useUserStore } from "../../../stores/user";

const {
  user,
  getUserPermissionsLabels,
  fetchUser,
  fetchSupportTicketsAssigned,
  supportTicketsAssigned,
  fetchSupportTicketsCreated,
  supportTicketsCreated,
} = useUsers();
const route = useRoute();
const router = useRouter();
const userStore = useUserStore();
const assignmentsLocalState = reactive({
  filters: [
    {
      condition: "not_in",
      column: "status",
      value: "closed",
    },
  ],
});

onMounted(async () => {
  await fetchUser(route.params.id).then((response) => {
    setPageTitle(`${response?.name}'s User Profile`);
  });
  if (userStore.user?.employee_profile?.is_manager) {
    fetchSupportTicketsAssigned(route.params.id, assignmentsLocalState);
    fetchSupportTicketsCreated(route.params.id, assignmentsLocalState);
  }
});
</script>

<template>
  <div v-if="user" class="px-4">
    <div class="d-flex align-center justify-space-between">
      <div class="d-flex align-center">
        <v-avatar class="d-flex align-center mt-2"
          ><v-img
            alt="Avatar"
            :src="gravatarUrl(user.email, { size: 100, default: 'retro' })"
          ></v-img
        ></v-avatar>
        <div>
          <v-card-title class="d-flex align-center pb-0">
            {{ user.name }}
            <span class="text-caption ml-2 text-medium-emphasis">{{ user.email }}</span>
          </v-card-title>
          <v-card-subtitle class="mt-0"
            ><span><strong>Tenant: </strong>{{ user.tenant?.name }}</span>
            <span v-if="user.client" class="ml-2"
              ><strong>Client: </strong>{{ user.client.name }}</span
            ></v-card-subtitle
          >
        </div>
      </div>
      <v-btn
        color="primary"
        @click="router.push(`/users/${user.id}/edit`)"
        v-if="
          userStore.user.id == user.id || userStore.hasPermissionTo('update_user_account')
        "
        >Edit User</v-btn
      >
    </div>

    <!-- <v-card class="mt-4">
      <v-card-title>User Details</v-card-title>
      <v-card-text>User details section</v-card-text>
    </v-card> -->

    <div class="w-100 justify-center" v-if="userStore.user?.employee_profile?.is_manager">
      <div class="mt-5 mb-5">
        <p class="ml-2 mr-2 mt-1 mb-2 bold">User Permissions:</p>
        <v-chip
          v-for="permission in getUserPermissionsLabels()"
          class="ml-1 mr-1 mt-1 mb-1"
          variant="elevated"
          size="small"
          color="#838787"
        >
          {{ permission }}
        </v-chip>
      </div>
      <div class="d-flex">
        <v-card class="mt-2 mr-2 w-50">
          <v-card-title>Assigned Tickets</v-card-title>
          <v-card-text class="scroll">
            <v-table v-if="supportTicketsAssigned">
              <thead>
                <tr>
                  <th class="text-left">Subject</th>
                  <th class="text-left">Category</th>
                  <th class="text-left">Status</th>
                  <th class="text-left">Priority</th>
                  <th class="text-left">Created</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="!supportTicketsAssigned">
                  <td colspan="12" class="text-center">Loading...</td>
                </tr>
                <tr v-if="supportTicketsAssigned && supportTicketsAssigned.length == 0">
                  <td colspan="12" class="text-center">None assigned</td>
                </tr>

                <tr
                  v-if="supportTicketsAssigned && supportTicketsAssigned.length > 0"
                  v-for="ticket in supportTicketsAssigned"
                  @dblclick="router.push(`/support-tickets/${ticket.id}`)"
                >
                  <td @click="router.push(`/support-tickets/${ticket.id}`)">
                    {{ ticket.title }}
                  </td>
                  <td>
                    <v-chip size="small" :color="ticketCategoryColor(ticket.category)">{{
                      ticket.category
                    }}</v-chip>
                  </td>

                  <td>
                    <v-chip size="small" :color="ticketStatusColor(ticket.status)">{{
                      ticket.status
                    }}</v-chip>
                  </td>
                  <td>
                    <v-chip size="small" :color="ticketPriorityColor(ticket.priority)">{{
                      ticket.priority
                    }}</v-chip>
                  </td>
                  <td>{{ montDayYearShortTime(ticket.created_at) }}</td>
                </tr>
              </tbody>
            </v-table>
          </v-card-text>
        </v-card>
        <v-card class="mt-2 ml-2 w-50">
          <v-card-title>Created Tickets</v-card-title>
          <v-card-text class="scroll">
            <v-table v-if="supportTicketsCreated">
              <thead>
                <tr>
                  <th class="text-left">Subject</th>
                  <th class="text-left">Category</th>
                  <th class="text-left">Status</th>
                  <th class="text-left">Priority</th>
                  <th class="text-left">Created</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="!supportTicketsCreated" colspan="12">
                  <td colspan="12" class="text-center">Loading...</td>
                </tr>
                <tr v-if="supportTicketsCreated && supportTicketsCreated.length == 0">
                  <td colspan="12" class="text-center">None created</td>
                </tr>
                <tr
                  v-if="supportTicketsCreated && supportTicketsCreated.length > 0"
                  v-for="ticket in supportTicketsCreated"
                  @dblclick="router.push(`/support-tickets/${ticket.id}`)"
                >
                  <td @click="router.push(`/support-tickets/${ticket.id}`)">
                    {{ ticket.title }}
                  </td>
                  <td>
                    <v-chip size="small" :color="ticketCategoryColor(ticket.category)">{{
                      ticket.category
                    }}</v-chip>
                  </td>
                  <td>
                    <v-chip size="small" :color="ticketStatusColor(ticket.status)">{{
                      ticket.status
                    }}</v-chip>
                  </td>
                  <td>
                    <v-chip size="small" :color="ticketPriorityColor(ticket.priority)">{{
                      ticket.priority
                    }}</v-chip>
                  </td>
                  <td>{{ montDayYearShortTime(ticket.created_at) }}</td>
                </tr>
              </tbody>
            </v-table>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </div>
</template>

<style scoped>
.scroll {
  height: 500px;
  overflow: scroll;
}
</style>
